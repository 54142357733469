(function($, Drupal) {
  var getTimeDifference = function(endtime) {
    var timediff = endtime - new Date();
    var seconds = Math.floor((timediff / 1000) % 60);
    var minutes = Math.floor((timediff / 1000 / 60) % 60);
    var hours = Math.floor((timediff / (1000 * 60 * 60)) % 24);
    var days = Math.floor(timediff / (1000 * 60 * 60 * 24));
    return {
      'total': timediff,
      'days': days,
      'hours': hours,
      'minutes': minutes,
      'seconds': seconds
    };
  };

  var initializeClock = function(itemClass, endtime) {
    var $clock = $('.' + itemClass);
    if (typeof $clock === 'undefined' || !$clock) {
      return;
    }
    var $daysElem = $clock.find('.js-days');
    var $hoursElem = $clock.find('.js-hours');
    var $minutesElem = $clock.find('.js-minutes');
    var $secondsElem = $clock.find('.js-seconds');
    var timediff = getTimeDifference(endtime);
    function updateClock() {
      if (timediff.total < 1000) {
        clearInterval(timeinterval); onTimerComplete(); return;
      }
      if (timediff.seconds === 0) {
        timediff.days = timediff.days === 0 ? 0 : timediff.hours === 0 ? timediff.days - 1 : timediff.days;
        timediff.hours = timediff.days === 0 && timediff.hours === 0 ? 0 : timediff.hours === 0 ? 23 : timediff.minutes === 0 ? timediff.hours - 1 : timediff.hours;
        timediff.minutes = timediff.hours === 0 && timediff.minutes === 0 ? 0 : timediff.minutes === 0 ? 59 : timediff.minutes - 1;
        timediff.seconds = 60;
      }
      timediff.seconds -= 1;
      timediff.total -= 1000;
      if ($daysElem) {
        $daysElem.html(timediff.days);
      }
      if ($hoursElem) {
        $hoursElem.html(timediff.hours);
      }
      if ($minutesElem) {
        $minutesElem.html(timediff.minutes);
      }
      if ($secondsElem) {
        $secondsElem.html(timediff.seconds);
      }
    }
    updateClock();
    var timeinterval = setInterval(updateClock, 1000);
  };
  var onTimerComplete = function() {
    var textComplete = '';
    var textCompleteElement = document.getElementById('textcomplete');
    if (typeof textCompleteElement !== 'undefined' && textCompleteElement) {
      textComplete = textCompleteElement.innerHTML;
    }
    if (textComplete.length) {
      var $countdownTimer = $('.js-countdown-clock');
      var $countdownText = $countdownTimer.find('.js-textcomplete');
      if ($countdownText) {
        $countdownTimer.find('.js-textcomplete').removeClass('hidden');
        $countdownText.html(textComplete).show();
        $countdownTimer.find('.js-coming-soon, .js-watch, .js-tag-line').hide();
      }
    }
  };

  Drupal.behaviors.countDownTimerV1 = {
    attach: function(context) {
      $('.page-wrapper').addClass('countdown-wrapper');
      var fullDateElement = document.getElementById('fulldatevalue');
      if (typeof fullDateElement !== 'undefined' && fullDateElement) {
        var fullDate = fullDateElement.innerHTML.replace(/&nbsp;/g, '');
        var endtime = new Date(fullDate);
        initializeClock('js-countdown-clock', endtime);
      }
    }
  };
}(jQuery, Drupal));
